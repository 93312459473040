<template lang="html">
  <div class="terminal flex fd-col"
  :class="{'--hide': terminalHidden}">
    <type-out class="type-out"
    v-for="(output,outputIndex) in TERMINAL_OUTPUT"
    :speed="output.speed"
    :text="output.text"
    :timing="output.timing"
    :outputId="outputIndex"
    :key="output.id"/>
  </div>
</template>

<script>
import typeOut from "@/components/typeOut"
import router from '@/router'

export default {
  name:"terminal-output2",
  components: {
    typeOut,
  },
  data(){
    return{
      terminalHidden: false,
    }
  },
  computed:{
    TERMINAL_OUTPUT(){
      return this.$store.getters.terminalOutput
    }
  },
  methods: {
    DELETE_OUTPUT(index){
      store.commit('deleteOutput', index)
    }
  },
}
</script>

<style lang="css">
.terminal{
  position: fixed;
  left: calc(var(--container-padding));
  bottom: calc(var(--footer-height));
  z-index: 200;
  transition: left var(--lin-norm);
}
.terminal.--hide{
  left: -9999px;
}
.type-out{
  cursor: pointer;
}
</style>
