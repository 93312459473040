import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {routes} from './router/index'
import store from '@/store'
import './registerServiceWorker'

import menuBar from "@/components/menuBar.vue"
import footerBar from "@/components/footerBar.vue"
import svgIcon from "@/components/svgIcon.vue"

Vue.config.productionTip = false

Vue.component('menuBar', menuBar)
Vue.component('svgIcon', svgIcon)
Vue.component('footerBar', footerBar)

import axios from 'axios'
import VueAxios from 'vue-axios'
import AxiosPlugin from 'vue-axios-cors'

Vue.use(VueAxios, axios)
Vue.use(AxiosPlugin)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
