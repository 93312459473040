export default{
  data(){
    return{
      themes: [
        {
          name: 'digital dream',
          mode: 'dark',
          palette: {
            darkest: 'hsl(240, 80%, 25%)',
            prim: 'hsl(236, 100%, 60%)',
            accent: 'hsl(150, 100%, 45%)',
            sec: 'hsl(310, 100%, 60%)',
          },
        },
        {
          name: 'northern lights',
          palette: {
            darkest: 'hsl(205, 80%, 15%)',
            prim: 'hsl(160, 100%, 50%)',
            accent: 'hsl(180, 100%, 60%)',
            sec: 'hsl(300, 100%, 75%)',
          },
        },
        {
          name: 'safran road',
          palette: {
            darkest: 'hsl(220, 30%, 20%)',
            prim: 'hsl(10, 100%, 60%)',
            accent: 'hsl(20, 100%, 80%)',
            sec: 'hsl(40, 30%, 80%)',
          },
        },
        {
          name: 'katana kiss',
          palette: {
            darkest: 'hsl(220, 38%, 22%)',
            prim: 'hsl(340, 100%, 60%)',
            accent: 'hsl(180, 80%, 60%)',
            sec: 'hsl(20, 100%, 90%)',
          },
        },
        // {
        //   name: 'burning lava',
        //   palette: {
        //     darkest: 'hsl(8, 100%, 25%)',
        //     prim: 'hsl(0, 100%, 50%)',
        //     accent: 'hsl(50, 100%, 60%)',
        //     sec: 'hsl(30, 70%, 60%)',
        //   },
        // },
        {
          name: 'carbon onyx',
          palette: {
            darkest: 'hsl(8, 2%, 10%)',
            prim: 'hsl(0, 4%, 15%)',
            accent: 'hsl(50, 50%, 30%)',
            sec: 'hsl(60, 30%, 60%)',
          },
        },
        {
          name: 'citrus combo',
          palette: {
            darkest: 'hsl(130, 25%, 15%)',
            prim: 'hsl(25, 100%, 55%)',
            accent: 'hsl(50, 100%, 50%)',
            sec: 'hsl(90, 80%, 50%)',
          },
        },
        {
          name: 'tokyo neons',
          palette: {
            darkest: 'hsl(240, 45%, 20%)',
            prim: 'hsl(260, 100%, 70%)',
            accent: 'hsl(184, 100%, 60%)',
            sec: 'hsl(330, 100%, 86%)',
          },
        },
        {
          name: 'summer beach',
          palette: {
            darkest: 'hsl(239, 70%, 40%)',
            prim: 'hsl(200, 100%, 50%)',
            accent: 'hsl(37, 70%, 80%)',
            sec: 'hsl(185, 100%, 80%)',
          },
        },
        {
          name: 'solarized hack',
          palette: {
            darkest: 'hsl(340, 60%, 30%)',
            prim: 'hsl(360, 100%, 60%)',
            accent: 'hsl(40, 100%, 60%)',
            sec: 'hsl(260, 100%, 80%)',
          },
        },
      ]
    }
  }
}
