import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import * as firebase from "firebase/app"
import * as firebaseAnalytics from "firebase/analytics"

var firebaseConfig = {
    apiKey: process.env.semantix_apiKey,
    authDomain: process.env.semantix_authDomain,
    databaseURL: process.env.semantix_databaseURL,
    projectId: process.env.semantix_projectId,
    storageBucket: process.env.semantix_storageBucket,
    messagingSenderId: process.env.semantix_messagingSenderId,
    appId: process.env.semantix_appId,
    measurementId: process.env.semantix_measurementId
  }
  // Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

// console.log(firebaseDatabase)

export default new Vuex.Store({
  state: {
    terminalOutput: [],
    terminalFlags: [],
    vocab: {},
  },
  mutations: {
    addOutput(state, obj){
      state.terminalOutput.unshift({
        text: obj.text,
        speed: 60,
        timing: obj.timing || 5000,
      })
    },
    addFlag(state, flag){
      this.state.terminalFlags.push(flag)
    },
    deleteOutput(state, index){
      state.terminalOutput.splice(index, 1)
    },
    clearOutput(state){
      Vue.set(state, 'terminalOutput', [] )
    },
    addVocab(state, val){
      // let arr = []
      // arr.push(val)
      state.vocab = {...val}
    }
  },
  getters: {
    terminalOutput: state => { return state.terminalOutput },
    vocabFromDb: state => {return state.vocab},
  },
  actions:{
    fetchVocab:({commit}) => {
      //you commit the mutation passing the snapshot value you recieved from firebase as payload to the mutation
      var vocabRef = firebase.database().ref("vocab")
      vocabRef.on('value', function(snap) {
        commit('addVocab', snap.val())
      })
    }
  },
})
