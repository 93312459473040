<template lang="html">
  <header class="flex fd-col fj-cent">
    <div class="menu-overlay" :class="{'overlay-on': menuOpened}">
    </div>
    <div class="container menu-bar desktop">
      <router-link to="/" class="menu-link">
        <div class="logo-block">
          <div class="logo-symbol">
            <svg-icon class="logo-symbol-svg" name="saphir"/>
            <!-- <div class="circle-1"></div>
            <div class="circle-2" :class="{'coin-spin': cryptoIsWorking}"></div> -->
          </div>
          <h1 class="logo-title">{{logoName}}</h1>
        </div>
      </router-link>
      <nav id="nav">
        <li v-for="(link, indexLink) in menuLinks">
          <router-link :to="link.url">{{link.desc}}</router-link>
        </li>
      </nav>
      <div class="menu-burger small-devices-only" :class="{__closed : menuOpened == false}" @click="toggleMenu">
        <div class="burger-line"></div>
        <div class="burger-line"></div>
        <div class="burger-line"></div>
        <div class="burger-line"></div>
      </div>
    </div>
    <div class="container small-devices-only flex fj-start fa-cent fd-col" :class="{__closed : menuOpened == false}">
      <div class="small-menu" @click="toggleMenu">
        <template v-for="(link, indexLink) in menuLinks">
          <router-link :to="link.url" :key="link.desc" class="flex fd-col fa-cent fj-cent">{{link.desc}}</router-link>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'menuBar',
  data(){
    return{
      logoName: 'ynck',
      menuOpened: false,
      cryptoIsWorking: false,
      menuLinks: [
        {
          desc: "about",
          url: "/about"
        },
        {
          desc: "interactive",
          url: "/interactive"
        },
        // {
        //   desc: "downloads",
        //   url: "/downloads"
        // },
        // {
        //   desc: "links",
        //   url: "/links"
        // },
        {
          desc:"contact",
          url: "/contact"
        }
      ]
    }
  },
  methods: {
    toggleMenu(){
      if(this.menuOpened == true){
        this.menuOpened = false
      }
      else {
        this.menuOpened = true
      }
    },
  }
}
</script>

<style>
header{
  position: fixed;
  z-index: 500;
  top: 0;
  width: 100%;
  height: var(--nav-h);
  /* background: linear-gradient(
    var(--bg-strongest) 80%, var(--bg-strongest-00) 100%
  ); */
  background-color: var(--bg-strongest-80);
  backdrop-filter: blur(4px);
  transition: color var(--lin-norm);
  will-change: color;
}
header .container{
  padding: 0 var(--container-padding);
}
.menu-overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  pointer-events: none;
  transition: background-color var(--lin-norm) 0.560s, opacity var(--lin-norm) 0.640s;
}
.overlay-on{
  pointer-events: all;
  background-color: var(--bg-strongest-96);
  transition: background-color var(--lin-norm), opacity var(--lin-norm);
}
.logo-block{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}
.logo-title{
  font-size: 1.6rem;
  margin-left: 4px;
  z-index: 0;
}
.logo-symbol{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.menu-link{
  color: unset;
}
.--mode-light.--prim-bright [class^='circle']{
  --color-prim: var(--color-prim-dark);
}
[class^="circle"]{
  position: absolute;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 0 1px var(--over-bg);
  border-radius: 50%;
}
.circle-2{
  box-shadow: 0 0 0 4px var(--color-prim) inset;
  transform: scale(.618);
  transition: transform var(--lin-slow-mo);
}
.coin-spin{
  animation: spin .5s linear infinite;
}
@keyframes spin{
  from {
    transform: scale(.618) rotateY(0deg);
  }
  to {
    transform: scale(.618) rotateY(180deg);
  }
}

#nav{
  height: var(--nav-h);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--over-bg);
}
#nav li{
  list-style-type: none;
}
#nav li:not(:last-child) {
  padding-right: 32px;
}
.menu-bar a{
  font-size: var(--menu-font-size);
}
#nav .router-link-active,
#nav .router-link-exact-active,
#nav .router-link-exact-active:hover {
  border-top: 3px solid var(--over-bg);
  padding-top: 20px;
}
.logo-block svg {
  height: var(--logo-size);
  width: var(--logo-size);
}
.container.desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-burger.small-devices-only {
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.container.small-devices-only.__closed {
  pointer-events: none;
}
.container.small-devices-only.__closed .small-menu {
  top: var(--nav-h);
  /* height: 66%; */
  transform: scale(0.96);
  pointer-events: none;
  transition: transform var(--lin-norm);
}
.container.small-devices-only .small-menu a{
  transition: font-size var(--lin-norm), opacity var(--lin-norm) 240ms;
  opacity: 1;
}
.container.small-devices-only.__closed .small-menu a{
  opacity: 0;
  transition: font-size var(--lin-norm) 240ms, opacity var(--lin-norm) 240ms;
}
.container.small-devices-only .small-menu{
  font-size: 1.2rem;
  display: flex;
  transform: scale(1);
  transition: transform var(--lin-norm) 240ms;
}
.container.small-devices-only.__closed .small-menu a:not(:last-child){
}
.container.small-devices-only .small-menu a:hover{
}
.small-menu a{
  font-size: 1.4rem;
}
.menu-burger{
  position: relative;
  display: flex;
  z-index: 400;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: calc(var(--size-unit)*4);
  width: calc(var(--size-unit)*4);
}
.menu-burger .burger-line{
  position: absolute;
  color: var(--over-bg);
}
.menu-burger .burger-line:nth-child(2){
  transform: rotate(-45deg);
  transform-origin: center;
}
.menu-burger .burger-line:nth-child(3){
  transform: rotate(45deg);
  transform-origin: center;
}
.menu-burger .burger-line:nth-child(1){
  opacity: 0;
  transform: translateY(8px);
  transform-origin: center;
}
.menu-burger .burger-line:nth-child(4){
  opacity: 0;
  transform: translateY(-8px);
  transform-origin: center;
}
.menu-burger.__closed .burger-line:nth-child(2){
  transform: rotate(0deg);
}
.menu-burger.__closed .burger-line:nth-child(3){
  transform: rotate(0deg);
}
.menu-burger.__closed .burger-line:nth-child(1){
  transform: translateY(-8px);
  opacity: 1;
}
.menu-burger.__closed .burger-line:nth-child(4){
  opacity: 1;
  transform: translateY(8px);
}
.menu-burger .burger-line:nth-child(2),
.menu-burger .burger-line:nth-child(3){
  transition: transform var(--lin-norm) 160ms, opacity var(--lin-norm);
}
.menu-burger .burger-line:nth-child(1),
.menu-burger .burger-line:nth-child(4){
  transition: transform var(--lin-norm), opacity var(--lin-norm);
}
.menu-burger.__closed .burger-line:nth-child(2),
.menu-burger.__closed .burger-line:nth-child(3){
  transition: transform var(--lin-norm), opacity var(--lin-norm);
}
.menu-burger.__closed .burger-line:nth-child(1),
.menu-burger.__closed .burger-line:nth-child(4){
  transition: transform var(--lin-norm) 160ms, opacity var(--lin-norm) 160ms;
}
.burger-line{
  height: 2px;
  width: 100%;
  background-color: var(--over-bg);
}
@media screen and (max-width: 960px){
  .logo-title{
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 769px){
  .container.small-devices-only .small-menu{
    display: none;
  }
  .menu-overlay{
    opacity: 0;
    background-color: transparent;
    pointer-events: none;
  }
}
@media screen and (max-width: 768px){
  .menu-burger.small-devices-only{
    display: flex;
  }
  #nav{
    display: none;
  }
  .container.small-devices-only{
    position: fixed;
    z-index: 1;
    width: 100%;
    /* height: 100%; */
    top: var(--nav-h);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container.small-devices-only .small-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 66%;
  }
  .small-menu a{
    transition: font-size var(--lin-fast);
  }
  .container.small-devices-only .small-menu a{
    padding: 16px 0;
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: inherit;
    opacity: 1;
  }
  .small-menu a:not(:last-child){
    /* border-bottom: 1px solid var(--grey); */
  }
}
@media screen and (max-width: 480px){
  .logo-title{
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 320px){
  .menu-burger{
    /* height: calc(var(--size-unit)*4); */
    /* width: calc(var(--size-unit)*3); */
  }
}
</style>
