<template lang="html">
  <transition name="type-text">
      <span class="terminal-output" @click="spliceOutput(outputId)">
        <progress class="output-timer-bar" :value="secLeft || 100" max="100"></progress>
        <!-- <span class="terminal-output-closer flex fd-col fa-cent fj-cent">&#10539;</span> -->
        <!-- <span ref="typeText">{{formatedStr}}</span> -->
        <span ref="typeText">{{formatedStr}}</span>
      </span>
  </transition>
</template>

<script>
export default {
  name: 'type-out',
  props: {
    speed: Number,
    text: String,
    timing: Number,
    outputId: Number,
  },
  data() {
    return {
      strOutput: '',
      pickIndex: 0,
      textTypeInterval: null,
      timeCounter: null,
      start: 0,
      secLeft: null,
    }
  },
  computed: {
    formatedStr(){
      return this.strOutput + " _"
    },
    formatedTimeLeft(){

    },
    // timeLeft(){
    //   return this.timing - (performance.now() - this.start)
    // }
  },
  methods: {
    spliceOutput(index){
      this.$store.commit('clearOutput', index)
    },
    addCharacter(index){
      this.strOutput += this.text.substring(this.pickIndex,this.pickIndex+1)
      if(this.pickIndex == this.text.length-1){
        clearInterval(this.textTypeInterval)
        // this.countDown()
      }
      else {
        this.pickIndex++
      }
    },
    typeText(index, time){
      this.textTypeInterval = setInterval( () => this.addCharacter(index), time )
      this.textTypeInterval
    },
    countDown(){
      this.timeCounter = setTimeout(
        () => {
          this.spliceOutput(this.outputId, 1)
        }, this.text.length*60 + this.timing
      )
      // this.timeCounter
    },

  },
  mounted () {
    this.typeText(this.text, this.speed)
    this.countDown()
    // this.containerVisible = true
  },
  beforeDestroy(){
    clearTimeout(this.timeCounter)
  }
}
</script>

<style lang="css">
.terminal-output{
  background-color: var(--bg-strongest);
  color: var(--over-bg);
  box-shadow: 0 4px 64px -8px var(--color-dark);
  padding: 8px 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  width: 272px;
  transition: transform var(--lin-norm);
}
.terminal-output.--visible{
  transform: translateX(0);
}
.terminal-output-closer{
  position: relative;
  left: 248px;
  top: -10px;
  background-color: var(--color-sec);
  color: var(--over-sec);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform-origin: center;
  line-height: 1;
  /* transform: translate(12px, -12px) rotate(0); */
  transition: transform var(--lin-norm);
  cursor: pointer;
}
.terminal-output-closer:hover{
  transform: rotate(180deg) scale(1.1);
}
.type-text-enter-active, .type-text-leave-active {
  transition-property: opacity, transform;
  transition-duration: 0.24s;
  /* transform-origin: center top; */
}
.type-text-enter-active {
  transition-delay: 0s;
}
.type-text-enter, .type-text-leave-active {
  transition-property: opacity, transform;
  transition-duration: 0.24s;
  opacity: 0;
  transform: translateX(-200px);
}
.output-timer-bar{
  display: block;
  width: 100%;
  border-radius: 0;
}
progress::-webkit-progress-bar {
  background-color: var(--bg-strong);
  height: 4px;
  width: 100%;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
}
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
}
progress::-webkit-progress-value {
  background-color: var(--color-accent);
}

</style>
