<template lang="html">
  <section class="footer-bar">
    <terminal-output2/>
    <div class="container flex fj-between fa-center">
      <div class="left">
        <terminal-input/>
      </div>
      <div class="right">
        <div class="footer-icon no-fill stroke-2" @click="switchTheme">
          <svgIcon name="contrast" @click="switchTheme"/>
        </div>
        <div class="footer-icon no-fill stroke-2" @click="openPop" v-click-outside="closePop">
          <svgIcon name="theme" @click="openPop"/>
        </div>
        <div class="pop-theme" :class="{__open: themeOpen}" ref="themePopUp">
          <ul class="pop-theme-list">
            <li class="pop-theme-item flex fa-center fj-between"
            v-for="(theme,tIndex) in themes"
            v-on:click="applyTheme(theme, tIndex, $event)"
            :class="{__selectedPalette: tIndex == themeIndex}">
            <div class="theme-palette">
              <div v-for="color in theme.palette" class="color-palette" :style="'background-color:'+ color">
              </div>
            </div>
            <p class="theme-name">{{theme.name}}</p>
          </li>
          </ul>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
import svgIcon from '@/components/svgIcon'
import terminalInput from '@/components/terminalInput.vue'
// import terminalOutput from '@/components/terminalOutput.vue'
import terminalOutput2 from '@/components/terminalOutput2.vue'
import ClickOutside from 'vue-click-outside'
import _checkBright from '@/mixins/_checkBackground'
import _themes from '@/mixins/_themes'

const htmlEl = document.querySelector('html')

export default {
  name: 'footerBar',
  components: {
    terminalInput,
    terminalOutput2
  },
  mixins: [
    _checkBright,
    _themes,
  ],
  directives: {
    ClickOutside
  },
  data(){
    return{
      themeContrast: 'dark',
      themeOpen: false,
      themeIndex: 0,
    }
  },
  methods: {
    openPop(){
      if(!this.themeOpen){
        this.themeOpen = true
      }
      else{
        this.themeOpen = false
      }
    },
    closePop(){
      this.themeOpen = false
    },
    makeColor(name, str, sat, lum){

    },
    strToHSL(str){

    },
    applyTheme(theme, index, event){
      const root = document.querySelector(':root')
      this.themeIndex = index
      if(event){
        event.preventDefault()
      }

      let darker = theme.palette.darkest.slice(0)
      .replace("hsl","").replace("(","").replace(")","").replace("%","").replace("%","").replace(" ", "").replace(" ", "").replace(" ", "").split(",").map( x => parseInt(x))
      darker[1] = darker[1] + 5
      darker[2] = darker[2] + 8

      let dark = darker.slice(0)
      let lightest = darker.slice(0)
      lightest[1] = 8
      lightest[2] = 100

      let lighter = lightest.slice(0)
      lighter[2] = 90

      let light = lighter.slice(0)
      light[2]= 80

      let darkester = theme.palette.darkest.slice(0)
      .replace("hsl","").replace("(","").replace(")","").replace("%","").replace("%","").replace(" ", "").replace(" ", "").replace(" ", "").split(",").map( x => parseInt(x))
      darkester[2] = darkester[2] - 15
      darkester[1] += "%"
      darkester[2] += "%"

      light[1] = light[1] + '%'
      light[2] = light[2] + '%'
      lighter[1] = lighter[1] + '%'
      lighter[2] = lighter[2] + '%'
      lightest[1] = lightest[1] + '%'
      lightest[2] = lightest[2] + '%'

      dark[1] = dark[1] + "%"
      dark[2] = dark[2] + 10 + "%"
      darker[1] = darker[1] + "%"
      darker[2] = darker[2] + "%"

      dark = 'hsl(' + dark.join(',') + ')'
      darker = 'hsl(' + darker.join(',') + ')'
      lightest = 'hsl(' + lightest.join(',') + ')'
      lighter = 'hsl(' + lighter.join(',') + ')'
      light = 'hsl(' + light.join(',') + ')'
      darkester = 'hsl(' + darkester.join(',') + ')'

      let primDark = theme.palette.prim.slice(0)
      .replace("hsl","").replace("(","").replace(")","").replace("%","").replace("%","").replace(" ", "").replace(" ", "").replace(" ", "").split(",").map( x => parseInt(x))
      primDark[2] = primDark[2] - 5
      primDark[1] = primDark[1] + "%"
      primDark[2] = primDark[2] + "%"

      primDark = 'hsl(' + primDark.join(',') + ')'

      let primLight = theme.palette.prim.slice(0)
      .replace("hsl","").replace("(","").replace(")","").replace("%","").replace("%","").replace(" ", "").replace(" ", "").replace(" ", "").split(",").map( x => parseInt(x))
      primLight[2] = primLight[2] + 35
      primLight[1] = primLight[1] + "%"
      primLight[2] = primLight[2] + "%"

      primLight = 'hsl(' + primLight.join(',') + ')'

      let primDarker = theme.palette.prim.slice(0)
      .replace("hsl","").replace("(","").replace(")","").replace("%","").replace("%","").replace(" ", "").replace(" ", "").replace(" ", "").split(",").map( x => parseInt(x))
      primDarker[2] = primDarker[2] - 30
      primDarker[1] = primDarker[1] + "%"
      primDarker[2] = primDarker[2] + "%"

      primDarker = 'hsl(' + primDarker.join(',') + ')'

      root.style.setProperty('--color-darkester', `${darkester}`)

      root.style.setProperty('--color-darkest', `${theme.palette.darkest}`)
      root.style.setProperty('--color-darkest-96', `${theme.palette.darkest.slice(0,-1).replace('hsl','hsla')},0.96)`)
      root.style.setProperty('--color-darkest-88', `${theme.palette.darkest.slice(0,-1).replace('hsl','hsla')},0.88)`)
      root.style.setProperty('--color-darkest-80', `${theme.palette.darkest.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-darkest-00', `${theme.palette.darkest.slice(0,-1).replace('hsl','hsla')},0)`)


      root.style.setProperty('--color-darker', `${darker}`)
      root.style.setProperty('--color-darker-96', `${darker.slice(0,-1).replace('hsl','hsla')},0.96)`)
      root.style.setProperty('--color-darker-80', `${darker.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-darker-00', `${darker.slice(0,-1).replace('hsl','hsla')},0)`)

      root.style.setProperty('--color-dark', `${dark}`)
      root.style.setProperty('--color-dark-96', `${dark.slice(0,-1).replace('hsl','hsla')},0.96)`)
      root.style.setProperty('--color-dark-80', `${dark.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-dark-00', `${dark.slice(0,-1).replace('hsl','hsla')},0)`)

      root.style.setProperty('--color-lightest', `${lightest}`)
      root.style.setProperty('--color-lightest-96', `${lightest.slice(0,-1).replace('hsl','hsla')},0.96)`)
      root.style.setProperty('--color-lightest-88', `${lightest.slice(0,-1).replace('hsl','hsla')},0.88)`)
      root.style.setProperty('--color-lightest-80', `${lightest.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-lightest-00', `${lightest.slice(0,-1).replace('hsl','hsla')},0)`)

      root.style.setProperty('--color-lighter', `${lighter}`)
      root.style.setProperty('--color-lighter-96', `${lighter.slice(0,-1).replace('hsl','hsla')},0.96)`)
      root.style.setProperty('--color-lighter-80', `${lighter.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-lighter-00', `${lighter.slice(0,-1).replace('hsl','hsla')},0)`)

      root.style.setProperty('--color-light', `${light}`)
      root.style.setProperty('--color-light-80', `${light.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-light-00', `${light.slice(0,-1).replace('hsl','hsla')},0)`)

      /* ------------- prims ---------- */

      root.style.setProperty('--color-prim-dark', `${primDark}`)
      root.style.setProperty('--color-prim-dark-96', `${primDark.slice(0,-1).replace('hsl','hsla')},0.96)`)
      root.style.setProperty('--color-prim-dark-88', `${primDark.slice(0,-1).replace('hsl','hsla')},0.88)`)
      root.style.setProperty('--color-prim-dark-80', `${primDark.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-prim-dark-00', `${primDark.slice(0,-1).replace('hsl','hsla')},0)`)

      root.style.setProperty('--color-prim-light', `${primLight}`)
      root.style.setProperty('--color-prim-darker', `${primDarker}`)


      root.style.setProperty('--color-prim', `${theme.palette.prim}`)
      root.style.setProperty('--color-prim-99', `${theme.palette.prim.slice(0,-1).replace('hsl','hsla')},0.99)`)
      root.style.setProperty('--color-prim-80', `${theme.palette.prim.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-prim-40', `${theme.palette.prim.slice(0,-1).replace('hsl','hsla')},0.4)`)
      root.style.setProperty('--color-prim-20', `${theme.palette.prim.slice(0,-1).replace('hsl','hsla')},0.2)`)
      root.style.setProperty('--color-prim-00', `${theme.palette.prim.slice(0,-1).replace('hsl','hsla')},0)`)

      /* ------------- sec ---------- */

      root.style.setProperty('--color-sec', `${theme.palette.sec}`)
      root.style.setProperty('--color-sec-99', `${theme.palette.sec.slice(0,-1).replace('hsl','hsla')},0.99)`)
      root.style.setProperty('--color-sec-80', `${theme.palette.sec.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-sec-60', `${theme.palette.sec.slice(0,-1).replace('hsl','hsla')},0.6)`)
      root.style.setProperty('--color-sec-40', `${theme.palette.sec.slice(0,-1).replace('hsl','hsla')},0.4)`)
      root.style.setProperty('--color-sec-20', `${theme.palette.sec.slice(0,-1).replace('hsl','hsla')},0.2)`)
      root.style.setProperty('--color-sec-00', `${theme.palette.sec.slice(0,-1).replace('hsl','hsla')},0)`)

      /* ------------- accent ---------- */

      root.style.setProperty('--color-accent', `${theme.palette.accent}`)
      root.style.setProperty('--color-accent-99', `${theme.palette.accent.slice(0,-1).replace('hsl','hsla')},0.99)`)
      root.style.setProperty('--color-accent-80', `${theme.palette.accent.slice(0,-1).replace('hsl','hsla')},0.8)`)
      root.style.setProperty('--color-accent-60', `${theme.palette.accent.slice(0,-1).replace('hsl','hsla')},0.6)`)
      root.style.setProperty('--color-accent-40', `${theme.palette.accent.slice(0,-1).replace('hsl','hsla')},0.4)`)
      root.style.setProperty('--color-accent-20', `${theme.palette.accent.slice(0,-1).replace('hsl','hsla')},0.2)`)
      root.style.setProperty('--color-accent-00', `${theme.palette.accent.slice(0,-1).replace('hsl','hsla')},0)`)

      this.addClassToHTML()
    },
    addClassToHTML(){
      htmlEl.classList = []
      // get selected Theme
      const theme = this.themes[this.themeIndex]
      // for each color in selected theme
      for(let color in theme.palette){
        let name = theme.name
        let hslArr = theme.palette[color].replace("hsl","").replace("(","").replace(")","").replace("%","").replace("%","").replace(" ", "").split(",")

        if(this._checkBrightness(this._hsl2rgb(hslArr)) > 142 && this._checkBrightness(this._hsl2rgb(hslArr)) < 172){
          // console.log(
          //   `${[color]} of ${name} is rgb : ${this._hsl2rgb(hslArr)}`,
          //   "brightness : ",
          //   this._checkBrightness(this._hsl2rgb(hslArr)
          //   )
          htmlEl.classList.add(`--${[color]}-bright`)
        }
        else if(this._checkBrightness(this._hsl2rgb(hslArr)) >= 172){
          htmlEl.classList.add(`--${[color]}-ultra`)
        }
      }
      this.setTheme()
    },
    switchTheme(){
      if(this.themeContrast == "dark"){
        this.themeContrast = "light"
        this.setTheme()
      }
      else if(this.themeContrast == "light"){
        this.themeContrast = "dark"
        this.setTheme()
      }
    },
    setTheme(){
      if(this.themeContrast == "dark"){
        htmlEl.classList.remove('--mode-light')
        htmlEl.classList.add('--mode-dark')
      }
      else if(this.themeContrast == "light"){
        htmlEl.classList.remove('--mode-dark')
        htmlEl.classList.add('--mode-light')
      }
    }
  },
  mounted(){
    // so that click outside doesn't work on the pop up window
    this.popupItem = this.$refs.themePopUp
    this.applyTheme(this.themes[0], 0)
  },
}
</script>

<style>
.footer-bar{
  position: fixed;
  z-index: 400;
  bottom: 0;
  left: 0; right: 0;
  height: var(--footer-height);
  border-top: 1px solid var(--color-prim);
  background-color: var(--bg-strongest-80);
  backdrop-filter: blur(4px);
}
.--mode-light.--prim-bright .footer-bar{
  border-top: 1px solid var(--color-prim-dark);
}
.footer-bar .container{
  padding: 0 var(--container-padding);
  height: var(--footer-height);
}
.footer-bar svg{
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: fill var(--lin-norm), stroke var(--lin-norm);
}
.footer-bar .stroke-2 svg{
  stroke-width: 2px;
  stroke: var(--over-bg);
}
.--prim-bright .footer-bar svg{
  --color-prim: var(--color-prim-dark);
}
.footer-bar .no-fill svg{
  fill: none;
}
.footer-bar .pop-theme{
  position: absolute;
  z-index: -1;
  bottom: calc( var(--footer-height) + 16px);
  right: var(--container-padding);
  width: auto;
  background-color: var(--bg-strongest);
  box-shadow: 0 4px 64px -16px var(--color-dark) ;
  transform: translateX(300px);
  transition: transform var(--lin-norm), opacity var(--lin-norm);
  border-radius: 4px;
  opacity: 0;
}
.footer-bar .pop-theme.__open{
  transform: translate(0,0);
  opacity: 1;
}
.pop-theme-list{
  overflow: hidden;
  border-radius: 4px;
}
.footer-bar .pop-theme .color-palette{
  height: 24px;
  width: 16px;
  display: inline-block;
}
.pop-theme-item{
  height: 40px;
  padding: 4px 8px;
}
form.pop-theme-item{
  border-bottom: 1px solid var(--bg-stronger-80);
}
.pop-theme-item:not(:last-of-type){
  border-bottom: 1px solid var(--bg-stronger-80);
}
.pop-theme li{
  list-style-type: none;
  cursor: pointer;
}
.no-hover .pop-theme-item{
  padding: 0;
}
.pop-theme li:not([class='no-hover']):hover{
  background-color: var(--over-bg);
  color: var(--bg-stronger);
}
.theme svg{
  fill: none;
}
.theme-name{
  margin-left: 24px;
}
.theme-palette{
  padding-top: 4px;
}
.__selectedPalette{
  transition: position var(--lin-norm);
}
.__selectedPalette::after{
  content: "";
  position: absolute;
  margin-top: -1px;
  right: -12px;
  height: 40px;
  width: 4px;
  background-color: var(--over-bg);
}
.switch-theme{
  position: absolute;
  -webkit-appearance: unset;
  height: 100%;
  color: var(--over-bg);
  border: none;
  left: -99999999999px;
}
.switch-theme:nth-of-type(1){
  border-right: 1px solid var(--over-bg);
}
.--mode-light.--prim-bright .switch-theme{
  --color-prim: var(--color-prim);
}
.switch-theme:checked + label{
  background-color: var(--color-prim);
  color: var(--over-prim);
}
.switch-theme label{
  cursor: pointer;
}
.pop-theme-item label{
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  line-height: 2.4;
}
.switch-wrapper{
  width: 50%;
  text-align: center;
  height: 100%;
}
.pop-theme-item label:hover{
  background-color: var(--color-prim);
  color: var(--over-prim);
}
.switch-theme:not(:checked) + label:hover{
  background-color: var(--over-bg);
  color: var(--bg-strongest);
}
.pop-theme-item label:first-of-type:after{
  border: 1px solid var(--over-bg);
}

.left,
.right{
  display: flex;
}
.footer-icon:not(:first-of-type){
  margin-left: 16px;
}
</style>
