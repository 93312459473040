export default {
  methods: {
    checkBackgroundColor() {
      let bodyBackground = window.getComputedStyle(document.documentElement).getPropertyValue('--body-background')
      bodyBackground.replace("rgb","").replace("(","").replace(")","").split(",")
      // console.log(this.hexToRgb(bodyBackground))
      let rgbBackground = this.hexToRgb(bodyBackground)
      this.updateTheme(rgbBackground)
    },
    _checkBrightness(arrArg){ // rgb array
      let r = arrArg[0],
      g = arrArg[1],
      b = arrArg[2]
      // http://www.w3.org/TR/AERT#color-contrast
      let brightness = Math.round( ((parseInt(r * 299)) + (parseInt(g * 587)) + (parseInt(b * 114)) )  / 1000 )
      // formula from : https://stackoverflow.com/questions/596216/formula-to-determine-brightness-of-rgb-color
      return brightness;
    },
    updateTheme(arr){
      if (this._checkBrightness(arr) > 150){
        // if background is a light tone
        document.querySelector('html').classList.add('--light-theme')
      }
      else{
        // if background is a dark tone back to default dark theme
        document.querySelector('html').classList.remove('--light-theme')
      }
    },
    _rgb2hsl(rgb) { // array
    	const r = rgb[0] / 255;
    	const g = rgb[1] / 255;
    	const b = rgb[2] / 255;
    	const min = Math.min(r, g, b);
    	const max = Math.max(r, g, b);
    	const delta = max - min;
    	let h;
    	let s;

    	if (max === min) {
    		h = 0;
    	} else if (r === max) {
    		h = (g - b) / delta;
    	} else if (g === max) {
    		h = 2 + (b - r) / delta;
    	} else if (b === max) {
    		h = 4 + (r - g) / delta;
    	}

    	h = Math.min(h * 60, 360);

    	if (h < 0) {
    		h += 360;
    	}

    	const l = (min + max) / 2;

    	if (max === min) {
    		s = 0;
    	} else if (l <= 0.5) {
    		s = delta / (max + min);
    	} else {
    		s = delta / (2 - max - min);
    	}
    	return [Math.round(h), Math.round(s * 100), Math.round(l * 100)];
    },
    _hsl2rgb(hsl) { // array
    	const h = parseInt(hsl[0]) / 360;
    	const s = parseInt(hsl[1]) / 100;
    	const l = parseInt(hsl[2]) / 100;
    	let t2;
    	let t3;
    	let val;

    	if (s === 0) {
    		val = l * 255;
    		return [val, val, val];
    	}

    	if (l < 0.5) {
    		t2 = l * (1 + s);
    	} else {
    		t2 = l + s - l * s;
    	}

    	const t1 = 2 * l - t2;

    	const rgb = [0, 0, 0];
    	for (let i = 0; i < 3; i++) {
    		t3 = h + 1 / 3 * -(i - 1);
    		if (t3 < 0) {
    			t3++;
    		}

    		if (t3 > 1) {
    			t3--;
    		}

    		if (6 * t3 < 1) {
    			val = t1 + (t2 - t1) * 6 * t3;
    		} else if (2 * t3 < 1) {
    			val = t2;
    		} else if (3 * t3 < 2) {
    			val = t1 + (t2 - t1) * (2 / 3 - t3) * 6;
    		} else {
    			val = t1;
    		}

    		rgb[i] = Math.round(val * 255);
    	}

    	return rgb;
    },
    hexToRgb(arg) {  // string
    	const match = arg.toString(16).match(/[a-f0-9]{6}|[a-f0-9]{3}/i)
    	if (!match) {
    		return [0, 0, 0]
    	}
    	let colorString = match[0]

    	if (match[0].length === 3) {
    		colorString = colorString.split('').map(char => {
    			return char + char
    		}).join('')
    	}
    	const integer = parseInt(colorString, 16)
    	const r = (integer >> 16) & 0xFF
    	const g = (integer >> 8) & 0xFF
    	const b = integer & 0xFF

    	return [r, g, b]
    }
  },
  mounted() {
  },
};
