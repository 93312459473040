import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
/* importing all views */
// import Contact from '@/views/Contact'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      terminal: {
        text: `Hello ! I'm a developper, and I love to work with modern web technologies.`,
        timing: 5000,
        visitFlag: 'home',
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue'),
    meta: {
      terminal: {
        text: `It's hard to speak about oneself, but I tried my best.`,
        timing: 5000,
        visitFlag: 'about',
      }
    }
  },
  {
    path: '/interactive',
    name: 'interactive',
    component: () => import('@/views/Interactive.vue'),
    meta: {
      terminal: {
        text: `These are experimental works going on in my lab. Check them out.`,
        timing: 5000,
        visitFlag: 'interactive',
      }
    }
  },
  {
    path: '/interactive/:selected',
    component: () => import('@/views/InteractiveItem.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      terminal: {
        text: `If you would like to speak about a project of yours or want to want to say hello, drop me a line.`,
        timing: 5000,
        visitFlag: 'contact',
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition){
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ savedPosition })
        }, 800)
      })
    }
    else if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash })
        }, 800)
      })
    }
    else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 800)
      })
    }
  }
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.terminal)) {
    window.clearTimeout()
    store.commit('clearOutput')
    next()
  }
  else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.matched.some(record => record.meta.terminal)) {
    // this route has meta key 'terminal', check if logged in
    setTimeout(
      () => {
        store.commit('addOutput', {...to.meta.terminal})
      }, 1000
    )
    // next()
  }
  else {
    // next()
  }
})


export default router
