<template lang="html">
  <div class="flex fj-cent fa-cent">
    <svgIcon class="terminal-input-icon stroke" name="bot"/>
    <input type="textarea" class="terminal-input-field" value="">
  </div>
</template>

<script>
import svgIcon from '@/components/svgIcon.vue'

export default {
  name: "terminal-input",
  data(){
    return {

    }
  },
  methods: {
  }
}
</script>

<style>
.terminal-input-icon{
  margin-right: 16px;
  transform-origin: center;
  stroke: var(--over-bg);
}
.terminal-input-field{
  background-color: transparent;
  box-shadow: none;
  height: 100%;
  width: 232px;
  outline: none;
  border: none;
  font-family: var(--ff-1), monospace;
  background-color: var(--color-prim-20);
  color: var(--over-prim);
  transition: width var(--lin-fast), background-color var(--lin-norm);
  border-radius: 2px;
}
.terminal-input-field.--prim-bright{
  color: var(--color-prim-dark);
}
.terminal-input-field:focus{
  outline: none;
  border: none;
  background-color: var(--color-prim-80);
  /* width: 320px; */
}
.accent-tilt{
  /* animation: tilt-1 .50s linear infinite alternate; */
}
.no-stroke svg{
  stroke: none;
}
.stroke svg{
  stroke: 1px var(--over-bg);
}
@keyframes tilt-1{
  from{
    fill: var(--color-prim);
  }
  to{
    fill: transparent;
  }
}
</style>
